import React, { useState } from 'react';

import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Dropdown from '../../../components/Dropdown/Dropdown';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import { getImage } from '../../../Utils/Utils';
import './styles.scss';

const classNames = require('classnames');

const Contact = ({ data, pageContext }) => {
  const { locale, slug } = pageContext;
  const [contactFormData] = useState(data.contactForm.edges[0].node);
  const imagesArray = data.allImages.edges;
  let pagesContact = [];

  contactFormData.relationships.field_contact_pages.forEach((item, i) => {
    pagesContact.push(
      { id: i, label: item.field_title.processed, link: item.field_link.url }
    );
  });

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={contactFormData.title}
      />
      <div className={classNames('page_template', 'page_contact')}>
        <PageBanner visuel={getImage(imagesArray, contactFormData.relationships?.field_image?.drupal_internal__mid)?.childImageSharp.fluid} scroll={false}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                // parentPage: {title: 'Acceuil', url: '/'},
                currentPage: { title: 'Contact', url: '/' + locale + slug },
                locale
              }}
            />
            <TitlePage
              color="color_white"
              title={contactFormData.title}
            />
          </div>
        </PageBanner>
        <div className="contact_form_selector">
          <h2>{contactFormData.field_form_title}</h2>
          <Dropdown
            customClass="dropdown-questions-footer"
            list={pagesContact}
          ></Dropdown>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MainContactTemplateQuery(
    $locale: String!
    $slug: String!
    $imageID: [Int!]
  ) {
    contactForm: allNodePageContact(
      filter: {
        langcode: {eq: $locale},
        path: {alias: {eq: $slug}}
      }
    ) {
      edges {
        node {
          langcode
          field_form_title
          title
          relationships {
            field_contact_pages {
              field_link {
                uri
                url
              }
              field_title {
                processed
              }
            }
            field_image {
              drupal_internal__mid
            }
            field_mobile_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"}, 
        drupal_internal__mid: {in: $imageID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default Contact;
